import { createReducer } from "@reduxjs/toolkit";
import {
  SEND_SCHEDULE_REQUEST,
  SEND_SCHEDULE_SUCCESS,
  SEND_SCHEDULE_FAILURE,
} from "../actions/send-schedule.actions";

export const initialState = {
  sentScheduleState: "",
  loading: false,
};

const sendScheduleRequest = (state) => ({ ...state, loading: true });

const sendScheduleSuccess = (state, { payload }) => {
  delete state.error;
  return {
    ...state,
    loading: false,
    sentScheduleState: payload,
  };
};

const sendScheduleFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload,
});

const sendScheduleReducer = createReducer(initialState, {
  [SEND_SCHEDULE_REQUEST]: sendScheduleRequest,
  [SEND_SCHEDULE_SUCCESS]: sendScheduleSuccess,
  [SEND_SCHEDULE_FAILURE]: sendScheduleFailure,
});

export default sendScheduleReducer;
