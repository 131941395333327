import { createAction } from "@reduxjs/toolkit";

export const SEND_FRANCHISE_REQUEST = "franchise/SEND_FRANCHISE_REQUEST";
export const SEND_FRANCHISE_SUCCESS = "franchise/SEND_FRANCHISE_SUCCESS";
export const SEND_FRANCHISE_FAILURE = "franchise/SEND_FRANCHISE_FAILURE";

export const sendFranchiseRequestAction = (payload) => ({
  type: SEND_FRANCHISE_REQUEST,
  payload: payload,
});
export const sendFranchiseSuccessAction = createAction(SEND_FRANCHISE_SUCCESS);
export const sendFranchiseFailureAction = createAction(SEND_FRANCHISE_FAILURE);
