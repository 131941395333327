import { takeEvery, put, call } from "redux-saga/effects";
import {
  SEND_FRANCHISE_REQUEST,
  sendFranchiseSuccessAction,
  sendFranchiseFailureAction,
} from "../actions/send-franchise.actions";
import { franchiseApi } from "../../services/apis";
import pNotification from "../../components/PNotification";

function* sendFranchiseSaga({ payload }) {
  try {
    const response = yield call(franchiseApi.sendFranchise, payload);
    if (response) {
      const notifyMessage = {
        type: "success",
        message: "Your request was successful!",
        description: "Check your inbox for the Franchise Brochure",
      };
      pNotification(notifyMessage);
      yield put(sendFranchiseSuccessAction("Your request has been received!"));
    }
  } catch (e) {
    yield put(sendFranchiseFailureAction(e));
  }
}

export default function* () {
  yield takeEvery(SEND_FRANCHISE_REQUEST, sendFranchiseSaga);
}
