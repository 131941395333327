import { createAction } from "@reduxjs/toolkit";

export const SEND_SCHEDULE_REQUEST = "schedule/SEND_SCHEDULE_REQUEST";
export const SEND_SCHEDULE_SUCCESS = "schedule/SEND_SCHEDULE_SUCCESS";
export const SEND_SCHEDULE_FAILURE = "schedule/SEND_SCHEDULE_FAILURE";

export const sendScheduleRequestAction = (payload) => ({
  type: SEND_SCHEDULE_REQUEST,
  payload: payload,
});
export const sendScheduleSuccessAction = createAction(SEND_SCHEDULE_SUCCESS);
export const sendScheduleFailureAction = createAction(SEND_SCHEDULE_FAILURE);
