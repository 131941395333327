import styled from "styled-components";

export const LoadingOverlay = styled.div`
  top: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000050;
  z-index: 999999;
`;
