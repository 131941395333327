import { takeEvery, put, call } from "redux-saga/effects";
import {
  SEND_CONTACT_REQUEST,
  sendContactSuccessAction,
  sendContactFailureAction,
} from "../actions/send-contact.actions";
import { contactApi } from "../../services/apis";
import pNotification from "../../components/PNotification";

function* sendContactSaga({ payload }) {
  try {
    const response = yield call(contactApi.sendContact, payload);
    if (response) {
      const notifyMessage = {
        type: "success",
        message: "Contact request Success",
        description: "Your request to schedule a FREE consultation has been received!",
      };
      pNotification(notifyMessage);
      yield put(sendContactSuccessAction("Your request to schedule a FREE consultation has been received!"));
    }
  } catch (e) {
    yield put(sendContactFailureAction(e));
  }
}

export default function* () {
  yield takeEvery(SEND_CONTACT_REQUEST, sendContactSaga);
}
