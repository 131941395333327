import * as gtag from "../../utils/gtag";

export const eventCallUs = () => {
  gtag.event({
    eventName: "eventGAPlayground",
    action: "phone number",
    category: "contact",
    label: "call us button",
  });
}

export const eventShowroomDirections = (showroom) => {
  gtag.event({
    eventName: "event_view_showroom_directions",
    action: "Clicks on showroom directions",
    category: "locations",
    label: showroom,
  });
}

export const eventClickPhoneNumber = (phone, action) => {
  gtag.event({
    eventName: "event_click_phone_number",
    action: action || "Clicks on phone numbers",
    category: "contact",
    label: phone || "801-572-7712",
  });
}

export const eventClickEmail = (email) => {
  gtag.event({
    eventName: "event_click_email",
    action: "Clicks on email address in footer",
    category: "contact",
    label: email || "customercare@classyclosetsut.com",
  });
}

export const eventFormSubmission = (url, category, { email, phone }) => {
  gtag.event({
    eventName: "event_generate_lead",
    action: "Submission",
    category: category || "lead",
    label: url || "Contact us Page",
    globalEmail: email,
    globalPhone: phone,
  });
}



export const experimentCheck = (callback, experiemntID) => {
  gtag.event({
    eventName: "optimize.activate",
    name: experiemntID,
    callback: callback

  })
}