import styled from "styled-components";
import { Input } from "antd";

const { TextArea } = Input;

export const CustomTextArea = styled(TextArea)`
  ${({ theme }) => theme.textStyles.inputElementValueDefault};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  margin-top: ${({ mtop }) => mtop};
  margin-bottom: ${({ mbottom }) => mbottom};
  margin-left: ${({ mleft }) => mleft};
  margin-right: ${({ mright }) => mright};
  color: ${({ textColor }) => textColor};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  ${({ minheight }) => minheight && `min-height: ${minheight}!important`};

  border-radius: 0px;
  border: solid 1px
    ${({ theme, validate }) =>
      validate === "error" ? theme.colors.negative : theme.colors.borderColor1};
  box-shadow: 0 3px 6px ${({ theme }) => theme.colors.shadow3};

  &:hover {
    border-right-width: 1px !important;
    border-color: ${({ theme, validate }) =>
      validate === "error" ? theme.colors.negative : theme.colors.focusColor};
  }
  &:focus {
    border-right-width: 1px !important;
    border-color: ${({ theme, validate }) =>
      validate === "error" ? theme.colors.negative : theme.colors.focusColor};
  }
  &::placeholder {
    /* ${({ theme }) => theme.textStyles.inputElementPlaceHoder} */
    color: ${({ theme }) => theme.colors.placeholder};
    ${({ fontSize }) => fontSize && `font-size: ${fontSize}`};
  }
`;

export const LabelText = styled.div`
  ${({ theme }) => theme.textStyles.formElementLabelDefault}
  padding-bottom: ${({ theme }) => theme.spacings.spacing_xxs};
  ${({ theme, focusing, validate }) =>
    focusing === "true" &&
    `color: ${
      validate === "error" ? theme.colors.negative : theme.colors.focusColor
    }`};
  color: ${({ theme, validate }) =>
    validate === "error" && theme.colors.negative};
`;

export const CustomDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.textStyles.inputElementValueDefault}
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  margin-top: ${({ mtop }) => mtop};
  margin-bottom: ${({ mbottom }) => mbottom};
  margin-left: ${({ mleft }) => mleft};
  margin-right: ${({ mright }) => mright};
  ${({ theme, bgType }) =>
    bgType === "disabled" &&
    `background-color: ${theme.colors.disabledBackground}`};
  padding-left: ${({ theme }) => theme.spacings.spacing_s};
  padding-right: ${({ theme }) => theme.spacings.spacing_s};
  padding-top: ${({ theme }) => theme.spacings.spacing_s};
  padding-bottom: ${({ theme }) => theme.spacings.spacing_s};
  color: ${({ theme, color }) => (color ? color : theme.colors.muted)};
  border-radius: 2px;
  border: solid 2px
    ${({ theme, validate }) =>
      validate === "error" ? theme.colors.negative : theme.colors.inputBorder};

  &:hover {
    border-right-width: 2px !important;
    border-color: ${({ theme, validate }) =>
      validate === "error" ? theme.colors.negative : theme.colors.focusColor};
  }
`;

export const CustomDivText = styled.div``;
export const CustomDivSuffix = styled.div``;
