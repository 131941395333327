import { createAction } from "@reduxjs/toolkit";

export const OPEN_SCHEDULE_POPUP_REQUEST = "ui/OPEN_SCHEDULE_POPUP_REQUEST";
export const OPEN_SCHEDULE_POPUP_SUCCESS = "ui/OPEN_SCHEDULE_POPUP_SUCCESS";
export const OPEN_SCHEDULE_POPUP_FAILURE = "ui/OPEN_SCHEDULE_POPUP_FAILURE";

export const openSchedulePopupRequestAction = (payload) => ({
  type: OPEN_SCHEDULE_POPUP_REQUEST,
  payload: payload,
});
export const openSchedulePopupSuccessAction = createAction(OPEN_SCHEDULE_POPUP_SUCCESS);
export const openSchedulePopupFailureAction = createAction(OPEN_SCHEDULE_POPUP_FAILURE);
