import { combineReducers } from "redux";
import uiStateData from "./ui.reducer";
import sentContactData from "./send-contact.reducer";
import sentFranchiseData from "./send-franchise.reducer";
import sentScheduleData from "./send-schedule.reducer";

export default combineReducers({
  uiStateData,
  sentContactData,
  sentFranchiseData,
  sentScheduleData,
});
