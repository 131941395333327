import { all } from "redux-saga/effects";
import uiSaga from "./ui.saga";
import sendContactSaga from "./send-contact.saga";
import sendFranchiseSaga from "./send-franchise.saga";
import sendScheduleSaga from "./send-schedule.saga";

export default function* () {
  yield all([
    uiSaga(),
    sendContactSaga(),
    sendFranchiseSaga(),
    sendScheduleSaga(),
  ]);
}
