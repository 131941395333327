import { createReducer } from "@reduxjs/toolkit";
import {
  SEND_CONTACT_REQUEST,
  SEND_CONTACT_SUCCESS,
  SEND_CONTACT_FAILURE,
} from "../actions/send-contact.actions";

export const initialState = {
  sentContactState: "",
  loading: false,
};

const sendContactRequest = (state) => ({ ...state, loading: true });

const sendContactSuccess = (state, { payload }) => {
  delete state.error;
  return {
    ...state,
    loading: false,
    sentContactState: payload,
  };
};

const sendContactFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload,
});

const sendContactReducer = createReducer(initialState, {
  [SEND_CONTACT_REQUEST]: sendContactRequest,
  [SEND_CONTACT_SUCCESS]: sendContactSuccess,
  [SEND_CONTACT_FAILURE]: sendContactFailure,
});

export default sendContactReducer;
