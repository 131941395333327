import { takeEvery, put } from "redux-saga/effects";
import {
  OPEN_SCHEDULE_POPUP_REQUEST,
  openSchedulePopupSuccessAction,
  openSchedulePopupFailureAction,
} from "../actions/ui.actions";

function* openSchedulePopupSaga({ payload }) {
  try {
    yield put(openSchedulePopupSuccessAction(payload));
  } catch (e) {
    yield put(openSchedulePopupFailureAction(e));
  }
}

export default function* () {
  yield takeEvery(OPEN_SCHEDULE_POPUP_REQUEST, openSchedulePopupSaga);
}
