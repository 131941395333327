import { createReducer } from "@reduxjs/toolkit";
import {
  OPEN_SCHEDULE_POPUP_REQUEST,
  OPEN_SCHEDULE_POPUP_SUCCESS,
  OPEN_SCHEDULE_POPUP_FAILURE,
} from "../actions/ui.actions";

export const initialState = {
  openSchedulePopup: false,
  loading: false,
};

const openSchedulePopupRequest = (state) => ({ ...state, loading: true });

const openSchedulePopupSuccess = (state, { payload }) => {
  delete state.error;
  return {
    ...state,
    loading: false,
    openSchedulePopup: payload,
  };
};

const openSchedulePopupFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload,
});

const uiReducer = createReducer(initialState, {
  [OPEN_SCHEDULE_POPUP_REQUEST]: openSchedulePopupRequest,
  [OPEN_SCHEDULE_POPUP_SUCCESS]: openSchedulePopupSuccess,
  [OPEN_SCHEDULE_POPUP_FAILURE]: openSchedulePopupFailure,
});

export default uiReducer;
