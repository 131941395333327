import Image from "next/image";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import dayjs from "dayjs";

import Loading from "../../components/Loading";
import {
  CustomModal,
  Container,
  ElementWrap,
  InlineElements,
  SubmitButton,
  TitleText,
  CustomInput,
  CTextArea,
  CloseBtn,
} from "./styled";
import { defaultFormValues } from "./_form";
import { sendScheduleRequestAction } from "../../redux/actions/send-schedule.actions";
import { openSchedulePopupRequestAction } from "../../redux/actions/ui.actions";
import { eventFormSubmission } from "../../services/analytics/ga4CustomEvents";
import IconTimesClose from "../../public/icons/icon_times-close.svg";
import pNotification from "../../components/PNotification";
import { validateEmail } from "../../utils/emailValidation";

const SchedulePopup = () => {
  const dispatch = useDispatch();
  const [cookie, setCookie] = useCookies(["popup"]);
  const { loading } = useSelector(
    ({ sentFranchiseData }) => sentFranchiseData || { loading: false },
  );
  const { openSchedulePopup } = useSelector(({ uiStateData }) => uiStateData);
  const [formValues, setFormValues] = useState(defaultFormValues);
  const [emailErr, setEmailErr] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(openSchedulePopup);

  const handleChange = (e) => {
    const key = e.target.name;
    const value = e.target.value;
    setFormValues({
      ...formValues,
      [key]: value,
    });
  };

  const handleSubmit = () => {
    if ((formValues.first_name !== "") &&
      validateEmail(formValues.email)) {
      dispatch(sendScheduleRequestAction(formValues));
      handleCancel();
      eventFormSubmission("Popup Form Submission", "popup");
      return
    }
    else {
      pNotification({
        type: "error",
        message: "Schedule request failed",
        description:
          "Please include Name, Email, and Phone!",
      })
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const isBeforeDay = (popupTime) =>
    dayjs(popupTime).isBefore(dayjs().format("YYYY-MM-DD", "day"));

  const persistPopupData = () => {
    setCookie("popup", dayjs().format("YYYY-MM-DD"), {
      path: "/",
      maxAge: 86400 * 3, // Expires after 3 day
      sameSite: true,
    });
  };

  const handleCancel = () => {
    persistPopupData();
    setIsModalVisible(false);
  };

  useEffect(() => {
    const handleVisiblePopup = () => {
      setTimeout(() => {
        showModal();
      }, 120000);
    };
    if (
      !cookie.popup ||
      cookie.popup === undefined ||
      isBeforeDay(cookie.popup)
    ) {
      handleVisiblePopup();
    }
  }, []);

  useEffect(() => {
    if (openSchedulePopup) {
      if (
        !cookie.popup ||
        cookie.popup === undefined ||
        isBeforeDay(cookie.popup)
      ) {
        showModal();
        dispatch(openSchedulePopupRequestAction(false));
      }
    }
  }, [openSchedulePopup]);

  return (
    <CustomModal
      visible={isModalVisible}
      closable={false}
      centered
      footer={null}
      mask={true}
    >
      <Container
        aria-label="schedule a consultation"
        aria-describedby="dialog-desc"
        role="alertdialog"
      >
        <CloseBtn aria-label="close" onClick={handleCancel} tabIndex={0}>
          {/* <i className="fal fa-times" aria-hidden /> */}
          <Image aria-hidden
            src={IconTimesClose}
            alt="close icon"
            layout="fill"
            loading="lazy"
            className="close-icon"
            quality={50}
          />
        </CloseBtn>
        <ElementWrap>
          <TitleText id="dialog-desc">
            Schedule a FREE in-home consultation today!
          </TitleText>
        </ElementWrap>
        <ElementWrap>
          <CustomInput
            name="first_name"
            placeholder="First Name*"
            onChange={handleChange}
            value={formValues.first_name}
            setElmInputErr={setFirstNameErr}
          />
        </ElementWrap>
        <ElementWrap>
          <CustomInput
            name="last_name"
            placeholder="Last Name"
            onChange={handleChange}
            value={formValues.last_name}
          />
        </ElementWrap>
        <ElementWrap>
          <CustomInput
            name="email"
            vType="email"
            placeholder="Email Address*"
            onChange={handleChange}
            value={formValues.email}
            setElmInputErr={setEmailErr}
          />
        </ElementWrap>
        <ElementWrap>
          <CustomInput
            name="phone"
            placeholder="Phone Number"
            onChange={handleChange}
            value={formValues.phone}
          />
        </ElementWrap>
        <InlineElements>
          <ElementWrap>
            <CustomInput
              name="city"
              placeholder="City"
              onChange={handleChange}
              value={formValues.city}
            />
          </ElementWrap>
          <ElementWrap>
            <CustomInput
              name="state"
              placeholder="State"
              onChange={handleChange}
              value={formValues.state}
            />
          </ElementWrap>
        </InlineElements>
        <ElementWrap>
          <CTextArea
            name="notes"
            minHeight="90px"
            placeholder="How Can We Help?"
            onChange={handleChange}
            value={formValues.notes}
          />
        </ElementWrap>
        <ElementWrap>
          <CustomInput
            name="referral_code"
            placeholder="Referral Code (optional)"
            onChange={handleChange}
            value={formValues.referral_code}
          />
        </ElementWrap>
        <input onChange={handleChange} className="form-bot1" name="form-bot1" defaultValue={""} />
        <ElementWrap>
          <SubmitButton
            ptype="default"
            pname="GET STARTED"
            onClick={handleSubmit}
            htmlType="submit"
          />
        </ElementWrap>
        <Loading loading={loading} />
      </Container>
    </CustomModal>
  );
};

export default SchedulePopup;