import React, { useEffect } from "react";
import Router, { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { CookiesProvider } from "react-cookie";
import { ThemeProvider } from "styled-components";
import TagManager from "react-gtm-module";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
// import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
// import Modal20OFF from "../containers/Modals/Modal20OFF";
// import ModalStorageSpace from "../containers/Modals/ModalStorageSpace";
import "antd/dist/antd.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/index.css";
import "../public/fonts/fonts.css";
import themes from "../constants/theme";
import SchedulePopup from "../containers/SchedulePopup";

import * as gtag from "../utils/gtag";
import { wrapper } from "../redux/store";
import { ScrollProvider } from "../context/ScrollProvider";
import { HeaderProvider } from "../context/HeaderProvider";
import useQueryCatcher from "../hooks/useQueryCatcher";
// import { useExperiment } from "../hooks/useEexperiment"

const LayoutContainer = dynamic(() => import("../components/LayoutContainer"));
//const SchedulePopup = dynamic(() => import("../containers/SchedulePopup"));
// const BrochureModal = dynamic(() => import("../containers/Modals/ModalBrochure"));

// Progress Bar configuration.
NProgress.configure({ easing: "ease", speed: 500, showSpinner: false });
// Binding events.
Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const tagManagerArgs = {
  gtmId: gtag.GA_TRACKING_ID,
};

// const fpjsPublicApiKey = process.env.NEXT_PUBLIC_FINGERPRINT_API_KEY;

function App({ Component, pageProps }) {
  // const [popup, setPopup] = useState(false);
  // const variant = useExperiment(process.env.NEXT_PUBLIC_EXPERIMENT_ID);
  // const [show, setShow] = useState(true);
  const router = useRouter();
  useQueryCatcher(router);
  const isAdmin = router.asPath.startsWith("/admin");
  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  return (
    <>
      {/* <FpjsProvider
        loadOptions={{
          apiKey: process.env.NEXT_PUBLIC_FINGERPRINT_API_KEY,
          endpoint: "https://fp.classyclosets.com"
        }}
      > */}
      <CookiesProvider>
        <LayoutContainer>
          <ThemeProvider theme={themes.default}>
            <ScrollProvider>
              <HeaderProvider>

                <Component
                  {...pageProps} />

              </HeaderProvider>
            </ScrollProvider>
            {!isAdmin && <SchedulePopup />}
            {/* <Modal20OFF visible={true} /> */}
            {/* <ModalStorageSpace visible={true} /> */}
          </ThemeProvider>
        </LayoutContainer>
      </CookiesProvider>
      {/* </FpjsProvider> */}
    </>
  );
}

export default wrapper.withRedux(App);
