import { useEffect } from "react";

export default function useQueryCatcher(router) {
  useEffect(() => {
    (async () => {
      const queryKeys = Object.keys(router.query);
      if (!queryKeys.length) return;
      setQueryStorage(router.query);
    })().catch(console.error);
  }, [router.query]);
}

export function setQueryStorage(queries) {
  const storedQueries = getQueryFromStorage();
  sessionStorage.setItem(
    "page-queries",
    JSON.stringify({ ...storedQueries, ...queries }),
  );
}

export function getQueryFromStorage() {
  try {
    return JSON.parse(sessionStorage.getItem("page-queries"));
  } catch {
    return {};
  }
}
