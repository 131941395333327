import { createReducer } from "@reduxjs/toolkit";
import {
  SEND_FRANCHISE_REQUEST,
  SEND_FRANCHISE_SUCCESS,
  SEND_FRANCHISE_FAILURE,
} from "../actions/send-franchise.actions";

export const initialState = {
  sentFranchiseState: "",
  loading: false,
};

const sendFranchiseRequest = (state) => ({ ...state, loading: true });

const sendFranchiseSuccess = (state, { payload }) => {
  delete state.error;
  return {
    ...state,
    loading: false,
    sentFranchiseState: payload,
  };
};

const sendFranchiseFailure = (state, { payload }) => ({
  ...state,
  loading: false,
  error: payload,
});

const sendFranchiseReducer = createReducer(initialState, {
  [SEND_FRANCHISE_REQUEST]: sendFranchiseRequest,
  [SEND_FRANCHISE_SUCCESS]: sendFranchiseSuccess,
  [SEND_FRANCHISE_FAILURE]: sendFranchiseFailure,
});

export default sendFranchiseReducer;
