import { createAction } from "@reduxjs/toolkit";

export const SEND_CONTACT_REQUEST = "contact/SEND_CONTACT_REQUEST";
export const SEND_CONTACT_SUCCESS = "contact/SEND_CONTACT_SUCCESS";
export const SEND_CONTACT_FAILURE = "contact/SEND_CONTACT_FAILURE";

export const sendContactRequestAction = (payload) => ({
  type: SEND_CONTACT_REQUEST,
  payload: payload,
});
export const sendContactSuccessAction = createAction(SEND_CONTACT_SUCCESS);
export const sendContactFailureAction = createAction(SEND_CONTACT_FAILURE);
