import React, { useState } from "react";
import {
  CustomTextArea,
  LabelText,
  CustomDiv,
  CustomDivText,
  CustomDivSuffix,
} from "./styled";

const PTextArea = ({
  elmType,
  bgType,
  value,
  label,
  height,
  width,
  minHeight,
  mTop,
  mBottom,
  mLeft,
  mRight,
  textColor,
  fontSize,
  errorStas,
  suffix,
  ...rest
}) => {
  const [focusing, setFocusing] = useState(false);

  const onFocus = () => {
    setFocusing(true);
  };

  return (
    <React.Fragment>
      {label && (
        <LabelText
          focusing={focusing.toString()}
          validate={errorStas ? "error" : "default"}
        >
          {label}
        </LabelText>
      )}
      {elmType === "div" ? (
        <CustomDiv
          height={height}
          width={width}
          mtop={mTop}
          mbottom={mBottom}
          mleft={mLeft}
          mright={mRight}
          textColor={textColor}
          bgType={bgType}
        >
          <CustomDivText>{value}</CustomDivText>
          {suffix && <CustomDivSuffix>{suffix}</CustomDivSuffix>}
        </CustomDiv>
      ) : (
        <CustomTextArea
          value={value}
          height={height}
          minheight={minHeight}
          onFocus={onFocus}
          width={width}
          mtop={mTop}
          mbottom={mBottom}
          mleft={mLeft}
          mright={mRight}
          color={textColor}
          fontSize={fontSize}
          {...rest}
        />
      )}
    </React.Fragment>
  );
};

export default PTextArea;
