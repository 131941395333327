import { takeEvery, put, call } from "redux-saga/effects";
import {
  SEND_SCHEDULE_REQUEST,
  sendScheduleSuccessAction,
  sendScheduleFailureAction,
} from "../actions/send-schedule.actions";
import { scheduleApi } from "../../services/apis";
import pNotification from "../../components/PNotification";

function* sendScheduleSaga({ payload }) {
  try {
    const response = yield call(scheduleApi.sendSchedule, payload);
    if (response) {
      const notifyMessage = {
        type: "success",
        message: "Schedule request Success",
        description:
          "Your request to schedule a FREE “In-Home Consultation” has been received!",
      };
      pNotification(notifyMessage);
      yield put(
        sendScheduleSuccessAction(
          "Your request to schedule a FREE “In-Home Consultation” has been received!",
        ),
      );
    }
  } catch (e) {
    yield put(sendScheduleFailureAction(e));
  }
}

export default function* () {
  yield takeEvery(SEND_SCHEDULE_REQUEST, sendScheduleSaga);
}
